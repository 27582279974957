import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "react-intl"



const Gdpr = ({ pageContext: { page }, } ) => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo title={"GDPR"} description={"Politică de confidențialitate"}/>
      <div className="page-standard">
        <h6> ‎ </h6>
        <h1>{intl.formatMessage({id: "gdpr1"})}</h1>

        <p>{intl.formatMessage({id: "gdpr2"})}</p>

        <p>{intl.formatMessage({id: "gdpr3"})}</p>

        <p>{intl.formatMessage({id: "gdpr4"})}</p>

        <h4>{intl.formatMessage({id: "gdpr5"})}</h4>
        <p>{intl.formatMessage({id: "gdpr6"})}</p>

        <p>{intl.formatMessage({id: "gdpr7"})}</p>

        <ul>
          <li><h4>{intl.formatMessage({id: "gdpr8"})}</h4>
            <p>{intl.formatMessage({id: "gdpr9"})}</p>
          </li>
          <li><h4>{intl.formatMessage({id: "gdpr10"})}</h4>
            <p>{intl.formatMessage({id: "gdpr11"})}</p>
          </li>
          <li><h4>{intl.formatMessage({id: "gdpr12"})}</h4>
            <p>{intl.formatMessage({id: "gdpr13"})}</p>
          </li>
          <li><h4>{intl.formatMessage({id: "gdpr14"})}</h4>
            <p>{intl.formatMessage({id: "gdpr15"})}</p>
          </li>
          <li><h4>{intl.formatMessage({id: "gdpr16"})}</h4>
            <p>{intl.formatMessage({id: "gdpr17"})}</p>
          </li>
          <li><h4>{intl.formatMessage({id: "gdpr18"})}</h4>
            <p>{intl.formatMessage({id: "gdpr19"})}</p>
          </li>
          <li><h4>{intl.formatMessage({id: "gdpr20"})}</h4>
            <p>{intl.formatMessage({id: "gdpr21"})}</p>
          </li>
          <li><h4>{intl.formatMessage({id: "gdpr22"})}</h4>
            <p>{intl.formatMessage({id: "gdpr23"})}</p>
          </li>
          <li><h4>{intl.formatMessage({id: "gdpr24"})}</h4>
            <p>{intl.formatMessage({id: "gdpr25"})}</p>
          </li>
          <li><h4>{intl.formatMessage({id: "gdpr26"})}</h4>
            <p>{intl.formatMessage({id: "gdpr27"})}</p>
          </li>
          <li><h4>{intl.formatMessage({id: "gdpr28"})}</h4>
            <p>{intl.formatMessage({id: "gdpr29"})}</p>
          </li>
        </ul>

        <h4>{intl.formatMessage({id: "gdpr30"})}</h4>
        <p>{intl.formatMessage({id: "gdpr31"})}</p>

        <p>{intl.formatMessage({id: "gdpr32"})}</p>
        <p>{intl.formatMessage({id: "gdpr33"})}</p>
        <p>{intl.formatMessage({id: "gdpr34"})}</p>
        <p>{intl.formatMessage({id: "gdpr35"})}</p>
        <p>{intl.formatMessage({id: "gdpr36"})}</p>
        <p>{intl.formatMessage({id: "gdpr37"})}</p>
        <p>{intl.formatMessage({id: "gdpr38"})}</p>

        <h4>{intl.formatMessage({id: "gdpr39"})}</h4>
        <p>{intl.formatMessage({id: "gdpr40"})}</p>

        <p>{intl.formatMessage({id: "gdpr41"})}</p>

        <p>{intl.formatMessage({id: "gdpr42"})}</p>

        <p>{intl.formatMessage({id: "gdpr43"})}</p>

        <p>{intl.formatMessage({id: "gdpr44"})}</p>

        <h4>{intl.formatMessage({id: "gdpr45"})}</h4>
        <p>{intl.formatMessage({id: "gdpr46"})}</p>

        <p>{intl.formatMessage({id: "gdpr47"})}</p>

        <h4>{intl.formatMessage({id: "gdpr48"})}</h4>
        <p>{intl.formatMessage({id: "gdpr49"})}</p>

        <h4>{intl.formatMessage({id: "gdpr50"})}</h4>
        <p>{intl.formatMessage({id: "gdpr51"})}</p>

        <p>{intl.formatMessage({id: "gdpr52"})}</p>

        <h4>{intl.formatMessage({id: "gdpr53"})}</h4>
        <ul>
          <li><h4>{intl.formatMessage({id: "gdpr54"})}</h4>
            <p>{intl.formatMessage({id: "gdpr55"})}</p>
          </li>
          <li><h4>{intl.formatMessage({id: "gdpr56"})}</h4>
            <p>{intl.formatMessage({id: "gdpr57"})}</p>

            <ul>
              <li>{intl.formatMessage({id: "gdpr58"})}</li>
              <li>{intl.formatMessage({id: "gdpr59"})}</li>
              <li>{intl.formatMessage({id: "gdpr60"})}</li>
              <li>{intl.formatMessage({id: "gdpr61"})}</li>
              <li>{intl.formatMessage({id: "gdpr62"})}</li>
              <li>{intl.formatMessage({id: "gdpr63"})}</li>
              <li>{intl.formatMessage({id: "gdpr64"})}</li>
              <li>{intl.formatMessage({id: "gdpr65"})}</li>
            </ul>
            <p>{intl.formatMessage({id: "gdpr66"})}</p>
            <p>{intl.formatMessage({id: "gdpr67"})}</p>
          </li>
          <li><h4>{intl.formatMessage({id: "gdpr68"})}</h4>
            <p>{intl.formatMessage({id: "gdpr69"})}</p>
            <p>{intl.formatMessage({id: "gdpr70"})}</p>
          </li>
          <li>
            <h4>{intl.formatMessage({id: "gdpr71"})}</h4>
            <p>{intl.formatMessage({id: "gdpr72"})}</p>
            <ul>
              <li>{intl.formatMessage({id: "gdpr73"})}</li>
              <li>{intl.formatMessage({id: "gdpr74"})}</li>
              <li>{intl.formatMessage({id: "gdpr75"})}</li>
              <li>{intl.formatMessage({id: "gdpr76"})}</li>
              <li>{intl.formatMessage({id: "gdpr77"})}</li>
              <li>{intl.formatMessage({id: "gdpr78"})}</li>
            </ul>
            <p>{intl.formatMessage({id: "gdpr79"})}</p>
            <p>{intl.formatMessage({id: "gdpr79"})}</p>
            <p>{intl.formatMessage({id: "gdpr81"})}</p>
          </li>
          <li> <h4>{intl.formatMessage({id: "gdpr82"})}</h4>
            <p> </p>
            <ul>
              <li>{intl.formatMessage({id: "gdpr83"})}
              </li>
              <li>{intl.formatMessage({id: "gdpr84"})}
              </li>
              <li>{intl.formatMessage({id: "gdpr85"})}
              </li>
              <li>{intl.formatMessage({id: "gdpr86"})}
              </li>

            </ul>
            <p>{intl.formatMessage({id: "gdpr87"})} {intl.formatMessage({id: "gdpr88"})}</p>
          </li>
          <li><h4>{intl.formatMessage({id: "gdpr89"})}</h4>

            <p>{intl.formatMessage({id: "gdpr90"})}</p>

            <p>{intl.formatMessage({id: "gdpr91"})} {intl.formatMessage({id: "gdpr92"})}</p>

          </li>
          <li>
            <h4>{intl.formatMessage({id: "gdpr93"})}</h4>

            <p>{intl.formatMessage({id: "gdpr94"})}</p>

            <p>{intl.formatMessage({id: "gdpr95"})}</p>

            <p>{intl.formatMessage({id: "gdpr96"})}</p>

            <p>{intl.formatMessage({id: "gdpr97"})} {intl.formatMessage({id: "gdpr98"})}</p>
          </li>
          <li><h4>{intl.formatMessage({id: "gdpr99"})}</h4>
            <p>{intl.formatMessage({id: "gdpr100"})}</p>

            <p>{intl.formatMessage({id: "gdpr101"})} {intl.formatMessage({id: "gdpr102"})}</p>

          </li>
          <li><h4>{intl.formatMessage({id: "gdpr103"})}</h4>
            <p></p>
            <p>{intl.formatMessage({id: "gdpr104"})} {intl.formatMessage({id: "gdpr105"})}</p>
          </li>
        </ul>

        <h4>{intl.formatMessage({id: "gdpr106"})}</h4>
        <p></p>

        <p>{intl.formatMessage({id: "gdpr108"})}</p>

        <p>{intl.formatMessage({id: "gdpr109"})}</p>

        <p>{intl.formatMessage({id: "gdpr110"})}</p>

        <p>{intl.formatMessage({id: "gdpr111"})}</p>

        <p>{intl.formatMessage({id: "gdpr112"})}</p>

        <p>{intl.formatMessage({id: "gdpr113"})}</p>

        <p>{intl.formatMessage({id: "gdpr114"})}</p>

        <p>{intl.formatMessage({id: "gdpr115"})}</p>

        <h4>{intl.formatMessage({id: "gdpr116"})}</h4>
        <p>{intl.formatMessage({id: "gdpr117"})}
        </p>

        <h4>{intl.formatMessage({id: "gdpr118"})}</h4>
        <p>{intl.formatMessage({id: "gdpr119"})}</p>

        <h4>{intl.formatMessage({id: "gdpr120"})}</h4>
        <p>{intl.formatMessage({id: "gdpr121"})}</p>

        <h4>{intl.formatMessage({id: "gdpr122"})}</h4>
        <p>{intl.formatMessage({id: "gdpr123"})}
        </p>

        <h4><b>{intl.formatMessage({id: "gdpr124"})}</b></h4>

      </div>
    </Layout>)
}

export default Gdpr
